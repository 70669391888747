.sidebar {
  @include responsive-properties((
      (margin, (v-xs-down: $grid-gutter-width 0)),
  ));

  .sidebar-hidden-xs & {
    @include responsive-properties((
        (display, (v-xs-down: none)),
    ));
  }

  .widget-title {
    margin: 0 0 $spacer-v-md;
    font-size: $font-size-h4 + 2;
  }

  .widget-inner {
    padding: $spacer-v-hd $spacer-h-md $spacer-v-lg;
  }

  .widget {
    max-width: 370px;

    + .widget {
      margin-top: $grid-gutter-width;
    }
    &.center-block{
      color: $white;
    }

    // Contact Details
    &.widget_wcd {

      .widget-title {
        color: $white;
      }

      p {
        margin: 0;

        a {
          color: $white;

          &:hover,
          &:active {
            color: darken($white, 15);
          }
        }
      }

      .address {

        p {
          margin-bottom: $line-height-computed / 2;
        }
      }

      .btn {
        margin-top: $spacer-v-md;
      }
    }

    // CTA
    &.widget_wcta {

      .widget-inner {
        position: relative;

        .widget-title {
          color: $white;
        }

        .description {
          margin: ($spacer-v / -1) 0 $spacer-v;
          color: $white;
        }

        .image {
          margin: 0 ($spacer-h-md / -1);

          img {
            min-width: 100%;
          }
        }

        .btn {
          margin-top: $spacer;

          &.has-image {
            position: absolute;
            bottom: $spacer-v-hd;
            margin: 0;
          }
        }
      }
    }

    // News
    &.widget_wnws {

      .widget-inner {

        .news-carousel {

          .news-slide {

            .news-title {
              margin: 0;
              font-size: $font-size-large + 1;
              font-family: $headings-font-family;

              a {
                color: $white;

                &:hover {
                  color: darken($white, 15);
                }
              }
            }

            .news-updated {
              display: block;
            }

            .news-summary {
              margin: $spacer-v-lg 0 ($spacer-v-lg - 13);
              font-size: $font-size-large;
              font-weight: $font-weight-light;
              line-height: $line-height-small;
              color:$white;
            }
          }
        }
      }
    }

    // Key Info
    &.widget_wki {
      max-width: none;

      .widget-title {
        color: inherit;
      }
    }
  }
}
