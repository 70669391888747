.table-info {
  text-align: right;
  display: none;

  i {
    font-size: $font-size-large;
  }

  p {
    font-size: small;
    margin-bottom: $spacer-v-xs;
  }
}

.table-container {

  .tablepress {
    font-size: $font-size-small;

    tfoot,
    thead {

      tr {

        th {
          background-color: $gray-dark;
          color: $white;
          border-bottom-width: 1px;
          padding-top: $spacer-v-sm;
          padding-bottom: $spacer-v-sm;
          vertical-align: middle;
        }
      }
    }
  }
}
