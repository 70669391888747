// Spacers
$spacer-xs:  5px !default;
$spacer-sm:  10px !default;
$spacer:     15px !default;
$spacer-md:  20px !default;
$spacer-lg:  30px !default;
$spacer-hd:  40px !default;

// Horizontal spacers
$spacer-h-xs:  $spacer-xs !default;
$spacer-h-sm:  $spacer-sm !default;
$spacer-h:     $spacer !default;
$spacer-h-md:  $spacer-md !default;
$spacer-h-lg:  $spacer-lg !default;
$spacer-h-hd:  $spacer-hd !default;

// Vertical spacers
$spacer-v-xs:  $spacer-xs !default;
$spacer-v-sm:  $spacer-sm !default;
$spacer-v:     $spacer !default;
$spacer-v-md:  $spacer-md !default;
$spacer-v-lg:  $spacer-lg !default;
$spacer-v-hd:  $spacer-hd !default;

// Paddings
$padding-base-vertical:       $spacer-v;
$padding-base-horizontal:     $spacer-h-md;

$padding-large-vertical:      $spacer-v;
$padding-large-horizontal:    $spacer-h-md;

$padding-small-vertical:      $spacer-v-xs;
$padding-small-horizontal:    $spacer-h-sm;

$padding-xs-vertical:         1px;
$padding-xs-horizontal:       $spacer-h-xs;
