.pf-item.term-carousel {

  .pf-tc-carousel-wrap {
    margin: 0 -1px;
  }

  .pf-tc-carousel {

    .pc-tc-slide {
      margin: 0 1px;
      outline: none !important;

      .pc-tc-link {

        .pc-tc-thumbnail {
          height: 150px;
          position: relative;

          .pc-tc-gas-icon {
            font-size: 90px;
            position: absolute;
            top: 50%;
            left: 50%;
            @include translateXY(-50%, -50%);
          }
        }

        .pc-tc-title {
          background-color: $white;
          margin: 0;
          padding: $spacer-md;
          font-size: $font-size-h6;
          display: table;
          width: 100%;

          span {
            display: table-cell;
            vertical-align: middle;
          }
        }

        &:hover {

          .pc-tc-title {
            background-color: darken($white, 10);
          }
        }
      }
    }
  }
}
