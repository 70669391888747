.page-header {
  position: relative;
  padding: 0;
  border: 0;
  margin: 0;

  // Default
  &.default {
    color: $white; 
    @include responsive-properties((
        (margin, (v: 0 ($grid-gutter-width / -2), v-sm-up: 0 0 $spacer-v-md)),
        (min-height, (v-sm-up: 330px)),
        (display, (v-sm-up: table)),
        (width, (v-sm-up: 100%)),
    ));

    &:after {
      content: "";
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($black, .45);
    }

    .ph-bg-helper {
      height: 180px;
      width: 100%;
    }

    .ph-caption {
      position: relative;
      z-index: 10;
      vertical-align: middle;
      @include responsive-properties((
          (padding, (v: $spacer-v-md ($grid-gutter-width / 2) $spacer-v-lg, v-sm-up: $spacer-v-hd 0 $spacer-v-hd $spacer-h-hd)),
          (background-color, (v-xs-down: $brand-primary)),
          (display, (v-sm-up: table-cell)),
      ));

      .ph-title {
        line-height: 1;
        margin: 0;
        color: inherit;
        @include break-word;
        @include responsive-properties((
            (font-size, (v: $font-size-h5 * 2, v-sm-up: $font-size-h1)),
        ));

        br {
          @include responsive-properties((
              (display, (v-xs-down: none)),
          ));
        }
      }

      .ph-lead {
        color: inherit;
        font-size: $font-size-large;
        margin: 25px 0 0;
        width: 100%; 
        @include responsive-properties((
            (width, (v-lg-up: 45%)),
        ));
      }

      .ph-anchors {
        @include responsive-properties((
            (margin-top, (v: $spacer-v, v-sm-up: $spacer-v-md)),
        ));

        .btn {
          margin: $spacer-xs $spacer-xs 0 0;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    &.bg-black {
      @include responsive-properties((
          (background-image, (v-xs-down: none !important)),
      ));
    }
  }

  // Background Carousel
  &.bg-carousel {
    position: relative;

    .ph-background-carousel {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .slick-list,
      .slick-track,
      .ph-background-slide {
        width: 100%;
        height: 100%;
      }
    }
  }

  // Gas Type
  &.gas-type {

    &:after {
      display: none;
    }

    .ph-caption {
      background-color: transparent;
      @include responsive-properties((
          (width, (v-sm-up: percentage(8/12))),
      ));
    }

    .ph-icon {
      vertical-align: middle;
      @include responsive-properties((
          (width, (v: percentage(4/12))),
          (display, (v: table-cell)),
          (padding, (v-sm: 0 $spacer-h-hd 0 0)),
      ));

      .ph-gas-icon {
        @include responsive-properties((
            (font-size, (v: 180px, v-md-up: 190px)),
        ));
      }
    }
  }

  // Map
  &.map {
    @include responsive-properties((
        (margin, (v: 0 ($grid-gutter-width / -2), v-sm-up: 0 0 $spacer-v-md)),
        (min-height, (v-xs-down: 180px)),
        (padding, (v-sm-up: $spacer-xs)),
    ));

    .map-container {
      display: block;
      position: relative;
      width: 100%;
      @include responsive-properties((
          (min-height, (v: 180px, v-sm-up: 320px)),
      ));

      .eds-map {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include kill-gmap;
      }
    }
  }

  // Product
  &.product {
    @include responsive-properties((
        (margin, (v: $grid-gutter-width 0, v-sm-up: 0 0 $grid-gutter-width)),
    ));

    .ph-button-container {
      @include responsive-properties((
          (width, (v-xxs-down: 100%)),
      ));
    }
  }
}
