.footer-widget.our-distributors {

  p {
    &:last-of-type {
      @include responsive-properties((
          (margin, (v-md-up: 0)),
      ));
    }
  }

  .row {
    width: 100%;
    height: 100%;
    margin-bottom: $spacer-v;
    @include responsive-properties((
        (display, (v-md-up: table)),
        (table-layout, (v-md-up: fixed)),
    ));

    [class^="col-"], [class*=" col-"] {
      height: 100%;
      vertical-align: top;
      @include responsive-properties((
          (display, (v-md-up: table-cell)),
          (float, (v-md-up: none)),
      ));

      &:last-of-type {
        vertical-align: middle;
        @include responsive-properties((
            (text-align, (v-md-up: right)),
        ));
      }
    }
  }
}
