.tax-eds_product_gas_type,
.tax-eds_product_industry,
.tax-eds_product_category {

  .products-header {
    padding: $spacer-v-md 0;

    .products-title {
      margin: 0;
      color: $text-color;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      font-size: $font-size-h3;

      .products-count {
        font-weight: $font-weight-base;
      }
    }
  }

  .products-row {
    margin: 0 (2px / -1);

    .type-eds_product {
      padding: 0 2px;
      @include responsive-properties((
          (width, (v-xxs-down: 100%)),
      ));

      .product-inner {
        margin-bottom: 4px;
        display: block;

        .product-thumbnail {
          border-bottom: 4px solid $brand-primary;
        }

        .product-summary {
          padding: ($spacer-v-md + $spacer-v-xs) $spacer-h-md 0;
          font-size: $font-size-base - 1;
          color: $text-color;

          .product-title {
            margin: 0 0 $spacer-v;
            font-size: $font-size-large; 
          }

          .product-description {

            p {

              &:last-of-type {
                margin: 0;
              }
            }
          }
        }

        .product-btn {
          margin: $spacer-v $spacer-h-md ($spacer-v-md + $spacer-v-xs);
        }
      }
    }
  }
}

.single-eds_product {

  .type-eds_product {

    .content-inner {

      + .content-inner {
        margin-top: $spacer-v-xs;
      }
    }

    .product-header {
      margin-bottom: $spacer-v-md;

      .product-title {
        margin: 0;
        font-size: $font-size-h2;
      }

      .product-subtitle {
        margin: $spacer-v-xs 0 0;
        color: $gray;
        font-size: $font-size-large;
      }
    }

    .product-button {
      margin-top: $spacer;
    }

    .product-content-title {
      margin: 0 0 $spacer-v;
      font-size: $font-size-h5;
      text-transform: uppercase;
    }

    .product-key-features {

      &.has-downloads {
        @include responsive-properties((
            (margin-bottom, (v: $spacer-v-lg, v-sm-up: $spacer-v-hd)),
        ));
      }
    }

    .product-key-info {
      background-color: $brand-primary;

      .product-content-title {
        color: $white;
      }

      .btn-read {
        display: none;
        margin-top: 10px;
        color: $white;
        font-family: $headings-font-family;
        border-bottom: 2px solid $white;

        &.active {
          display: inline-block;
        }

        &:hover {
          color: darken($white, 10);
          border-color: darken($white, 10);
        }
      }

      .table-container {
        color: $white;

        .table-responsive {
          overflow-y: hidden;
          max-height: 250px;

          &.active {
            max-height: none;
          }
        }

        .tablepress {
          margin: 0;

          tfoot,
          thead {

            tr {

              th {
                background-color: darken($brand-primary, 10);
                border: 1px solid $white;
              }
            }
          }

          tbody {

            tr {

              td {
                border: 1px solid $white;
              }
            }
          }

          &.table-striped {

            tbody {

              tr {

                &.odd {
                  background-color: $brand-primary, 4;

                  &:hover {
                    background-color: darken($brand-primary, 2);
                  }
                }

                &.even {
                  background-color: darken($brand-primary, 4);

                  &:hover {
                    background-color: darken($brand-primary, 6);
                  }
                }
              }
            }
          }
        }
      }

      .tablepress-table-description {
        color: $white;
      }
    }

    .product-key {
      margin: 0;
      padding-left: $spacer-h-md;

      > li {
        margin-bottom: $spacer-v-sm;

        .pk-title {
          margin: 0;
          font-family: $headings-font-family;
          font-size: $font-size-base - 1;
          color: $text-color;
        }

        .pk-description {
          color: $text-color;
          font-size: $font-size-base - 1;
          line-height: $line-height-small;

          p {

            &:last-of-type {
              margin: 0;
            }
          }
        }
      }

      &.white {

        > li {
          color: $white;

          .pk-title,
          .pk-description {
            color: $white;
          }
        }
      }
    }

    .product-gallery-wrap {
      margin-bottom: $grid-gutter-width;

      .product-gallery {

        .product-gallery-slide {
          img {
            min-width: 100%;
          }
        }
      }

      .product-thumbnails {
        margin-top: $spacer-v-sm;

        .product-thubmnail-slide {
          margin: 0 $spacer-h-xs;
          cursor: pointer;

          img {
            width: 100px;
            height: 100px;
          }

          &:hover {
            opacity: .8;
          }
        }
      }
    }
  }
}
