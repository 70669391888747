.social-links {
  margin: 0;
  display: inline-flex;

  > li {
    display: inline-flex;
    margin: 0 15px 0 0;

    > a {
      color: $white;
      background-color: $brand-primary; 
      width: $header-top-height - 12;
      height: $header-top-height - 12;
      display: block;
      text-align: center;
      font-size: $font-size-large;
      line-height: 1;
      border-radius: 100%;
      padding-top: ($header-top-height - 12 - $font-size-large) / 2;

      &:hover,
      &:active {
        background-color: darken($brand-success, 10);
      }
    }
  }
}
