@mixin button-border-variant($color, $color-hover, $background, $border) {
  color: $color;
  background-color: transparent;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color-hover;
    background-color: $background;
    border-color: $border;
  }
  &:hover {
    color: $color-hover;
    background-color: $background;
    border-color: $border;
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color-hover;
    background-color: $background;
    border-color: $border;

    &:hover,
    &:focus,
    &.focus {
      color: $color-hover;
      background-color: $background;
      border-color: $border;
    }
  }
}
