.locations-map-wrap {
  position: relative;
  display: inline-block;

  .marker {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 2px solid $brand-primary;
    margin: -5px 0 0 -5px;
    display: block;
    position: absolute;
    right: auto;
    bottom: auto;
    cursor: pointer;
    z-index: 1;

    &.small {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      margin: -3px 0 0 -3px;
    }

    &.active,
    &:hover {
      background-color: $brand-primary;
      z-index: 10;
    }
  }
}

