.banner,
.side-nav {

  .nav {

    li {

      a {
        font-family: $headings-font-family;

        i {
          margin-left: $spacer-h-xs;
        }
      }
    }
  }
}

.banner {
  position: relative;
  z-index: $header-brand-zindex;
  background-color: $header-bg;

  .nav {
    display: flex;

    > li {
      display: inline-flex;

      > a {
        display: flex;
        align-items: center;
      }
    }
  }

  .top {
    @include clearfix;
    height: $header-top-height;
    background-color: $brand-primary;

    .social-links {
      float: right;
      margin-top: 6px;
    }

    .nav-top {
      float: right;
      margin-left: $spacer-h-lg;

      .nav {

        > li {

          a {
            font-size: $font-size-base - 1;
          }

          > a {
            color: $white;
            height: $header-top-height;
            line-height: $header-top-height;
            padding: 0 $spacer-h-sm;

            &:hover,
            &:focus,
            &:active {
              background-color: #00804a;
            }
          }

          &.open,
          &.active {

            > a {
              background-color: #00804a;
            }
          }
        }
      }
    }
  }

  .bottom {
    @include clearfix;

    .container {
      position: relative;
    }

    .brand {
      background-color: $header-bg;
      display: inline-block;
      @include responsive-properties((
          (padding, (v: $spacer-v 0 $spacer-v, v-sm-up: $spacer-v $spacer-h-md)),
          (margin-top, (v-sm-up: $header-top-height / -1)),
      ));
    }

    .btn-toggle-side {
      right: 0;
      color: $text-color;
      @include responsive-properties((
          (position, (v: fixed, v-sm-up: absolute)),
          (top, (v: 0, v-sm-up: 50%)),
          (margin-top, (v-sm-up: $sidenav-toggle-height / -2)),
      ));
    }

    .nav-primary {

      .nav {
        position: absolute;
        top: 0;
        bottom: 0;
        right: $grid-gutter-width / 2;

        > li {

          a {
            font-size: $font-size-base + 1;
          }

          > a {
            color: $gray-dark;
            padding: 0 $spacer-h-sm;
            &:hover {
              background-color:transparent;
            }
            &:focus {
              background-color:transparent;
            }

            > i {
              color: $brand-primary;
            }
          }

          &.active {

            > a {
              background-color: transparent;
            }
          }

          &.open {

            > a {
              background-color: #00804a;
              color: $white;

              > i {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

.btn-toggle-side {
  text-transform: uppercase;
  font-family: $headings-font-family;
  font-size: $font-size-base + 1;
  padding: 0 ($grid-gutter-width / 2);
  height: $sidenav-toggle-height;

  span {
    display: inline-block;
    @include responsive-properties((
        (margin-right, (v: $spacer-h-sm / -1, v-sm-up: $spacer-h-xs / - 1)),
    ));
  }

  i {
    position: relative;
    display: inline-block;
    top: $sidenav-toggle-bar-margin / -1;
    width: $sidenav-toggle-bar-width;
    height: $sidenav-toggle-bar-height;
    background-color: $sidenav-toggle-bar-bg;
    vertical-align: middle;

    &:before,
    &:after {
      content: "";
      width: $sidenav-toggle-bar-width;
      height: $sidenav-toggle-bar-height;
      display: inline-block;
      position: absolute;
      left: 0;
      background-color: $sidenav-toggle-bar-bg;
      @include transition(transform .5s ease);
    }

    &:before {
      @include translateY($sidenav-toggle-bar-height + $sidenav-toggle-bar-margin);
    }

    &:after {
      @include translateY(($sidenav-toggle-bar-height + $sidenav-toggle-bar-margin) / -1);
    }
  }

  &:hover {
    i {
      background-color: darken($sidenav-toggle-bar-bg, 10%);

      &:before,
      &:after {
        background-color: darken($sidenav-toggle-bar-bg, 10%);
      }
    }
  }
}

.side-nav-backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: $sidenav-backdrop-zindex;
  background: $backdrop-bg;
  @include transition(visibility .3s ease-out, opacity .3s ease-out);
  @include responsive-properties((
      (opacity, (v: 0, v-md-up: 0 !important)),
      (visibility, (v: hidden, v-md-up: hidden !important)),
  ));

  .side-nav-active & {
    visibility: visible;
    opacity: 1;
  }
}

.side-nav-container {
  overflow-y: scroll;
  z-index: $sidenav-zindex;
  @include transition(all .3s ease-out);
  position: fixed;
  right: 0;
  top: 0;
  min-height: 100%;
  width: $sidenav-width;
  margin-right: $sidenav-width / -1;

  .side-nav-active & {
    @include responsive-properties((
        (right, (v: $sidenav-width, v-md-up: 0)),
    ));
  }

  .btn-toggle-side {
    position: absolute;
    top: 0;
    left: 0;
    @include translateX(-100%);

    i {
      background-color: transparent;

      &:before,
      &:after {
        background-color: $white;
      }

      &:before {
        -webkit-transform: translateY(0) rotate(45deg);
        -ms-transform: translateY(0) rotate(45deg);
        transform: translateY(0) rotate(45deg);
      }

      &:after {
        -webkit-transform: translateY(0) rotate(-45deg);
        -ms-transform: translateY(0) rotate(-45deg);
        transform: translateY(0) rotate(-45deg);
      }
    }

    &:hover {

      i {

        &:before,
        &:after {
          background-color: darken($white, 15);
        }
      }
    }
  }

  .side-nav {
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    min-height: 100%;
    background-color: $sidenav-bg;
    width: $sidenav-inner-width;

    .side-nav-inner {
      padding: $spacer-v-lg 0;
      background-color: $sidenav-bg;

      .nav {
        padding-bottom: $spacer-v-md;

        > li {

          > a {
            font-size: $font-size-base + 1;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover,
            &:focus,
            &:active {
              background-color: $brand-success;
            }
          }

          &.open,
          &.active {

            > a {
              background-color: $brand-success;
            }
          }
        }
      }
    }
  }
}

.sticky-nav {
  position: fixed;
  z-index: $header-sticky-bar-zindex;
  top: 0;
  left: 0;
  right: 0;
  height: $sidenav-toggle-height;
  background-color: $white;

  .container {
    position: relative;
  }

  .btn {
    position: absolute;
    top: 0;
  }

  .btn-scroll {
    left: 0;
    font-size: $font-size-h4;
    font-family: $headings-font-family;
    color: $sidenav-toggle-bar-bg;
    padding: 0 ($grid-gutter-width / 2);
    height: $sidenav-toggle-height;
    opacity: 0;

    i {
      margin: 0;
      vertical-align: top;
    }

    &:hover {
      color: darken($sidenav-toggle-bar-bg, 10);
    }
  }

  .btn-toggle-side {
    right: 0;
  }

  .sticky-nav-active & {
    @include box-shadow(0px 0px 5px 0px rgba($black, .3));

    .btn-scroll {
      opacity: 1;
    }
  }
}
