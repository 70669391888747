// Forms

$input-bg:                       $white;
$input-color:                    $text-color;
$input-border:                   $gray-light;
$input-gutter-width:             $spacer-md !default;

$input-border-focus:             $brand-primary;
$input-color-placeholder:        $gray-light;

$form-group-margin-bottom:       $input-gutter-width;
$radio-checkbox-margin-bottom:   $input-gutter-width / 2 !default;

$input-font-size:                $font-size-small !default;
$input-line-height:              $line-height-small !default;

$input-height-base:              ($line-height-computed-small + ($padding-base-vertical * 2) + 2);

