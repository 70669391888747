.btn {
  outline: none !important; 
  font-family: $headings-font-family;
  font-size: $btn-font-size;
  line-height: $btn-line-height;
  white-space: normal;

  i {
    vertical-align: middle;
    margin-left: $spacer-h-md;
  }
}

.btn-lg {
  font-size: $btn-font-size-lg;
  line-height: $btn-line-height-lg;
}

.btn-reset {
  background-color: transparent;
  box-shadow: none !important;
  padding: 0;
  border: 0;
}

.btn-white {
  @include button-variant($btn-white-color, $btn-white-bg, $btn-white-border);
}
.btn-dark {
  @include button-variant($btn-dark-color, $btn-dark-bg, $btn-dark-border);
}

// Border only Buttons

[class^="btn-border-"], [class*=" btn-border-"] {
  font-weight: $btn-border-font-weight;
  border-radius: $btn-border-border-radius;
}

.btn-border-default {
  @include button-border-variant($btn-b-default-color, $btn-b-default-h-color, $btn-b-default-bg, $btn-b-default-border);
}

.btn-border-primary {
  @include button-border-variant($btn-b-primary-color, $btn-b-primary-h-color, $btn-b-primary-bg, $btn-b-primary-border);
}

.btn-border-success {
  @include button-border-variant($btn-b-success-color, $btn-b-success-h-color, $btn-b-success-bg, $btn-b-success-border);
}

.btn-border-info {
  @include button-border-variant($btn-b-info-color, $btn-b-info-h-color, $btn-b-info-bg, $btn-b-info-border);
}

.btn-border-warning {
  @include button-border-variant($btn-b-warning-color, $btn-b-warning-h-color, $btn-b-warning-bg, $btn-b-warning-border);
}

.btn-border-danger {
  @include button-border-variant($btn-b-danger-color, $btn-b-danger-h-color, $btn-b-danger-bg, $btn-b-danger-border);
}
