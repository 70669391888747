.pc-item.l-team {
  margin-bottom: $spacer-v-md / -2;

  .type-eds_team_member {
    margin-bottom: $spacer-v-lg;
    @include responsive-properties((
        (width, (v-xxs-down: 100%)),
    ));

    .member-link {
      outline: none;

      .member-thumbnail {

        img {
          min-width: 100%;
        }
      }

      .member-title,
      .member-job {
        font-family: $font-family-base;
        font-size: $font-size-h6;
        margin: 0;
      }

      .member-title {
        font-weight: $font-weight-bold;
        margin-top: $spacer-v;
      }

      .member-job {
        color: $text-color;
        margin-top: $spacer-v-xs;
      }

      &:hover {
        .member-thumbnail {
          opacity: .8;
        }
      }
    }
  }
}
