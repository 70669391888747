// Colours

$white:                 #fff !default;
$black:                 #000 !default;

$gray-base:             $black;
$gray-dark:             #63666A;
$gray:                  #989b9c;
$gray-light:            #97999B;
$gray-lighter:          #ebecec;

$brand-primary:         #009F4D;
$brand-success:         #015236;
$brand-info:            $gray-light;
$brand-warning:         #bbd7cd;
$brand-danger:          #ef3b3b;
