.file-download {
  @include list-unstyled;
  margin: 0;

  > li {
    margin-bottom: $spacer-v-sm;

    .fd-file {
      display: inline-block;
      position: relative;
      padding-left: $spacer-h-lg;

      .fd-icon {
        color: $brand-primary;
        position: absolute;
        left: 0;
        font-size: $font-size-h4;
      }

      .fd-title {
        margin: 0 0 $spacer-v-xs;
        padding-top: $spacer-v-xs;
        font-family: $font-family-base;
        font-size: $font-size-base - 1;
        font-family: $headings-font-family;
        color: $text-color;
      }

      .fd-description {
        color: $text-color;
        margin: 0;
        font-size: $font-size-base - 1;
        line-height: $line-height-small;
      }

      &:hover {

        .fd-icon {
          color: $text-color;
        }
      }
    }
  }

  &.white {

    .fd-file {

      .fd-icon,
      .fd-title,
      .fd-description {
        color: $white;
      }

      &:hover {
        .fd-icon {
          color: darken($white, 15);
        }
      }
    }
  }
}
