.page-template-template-history-php {

  .history-content {
    padding-top: 65px;
    position: relative;
    @include responsive-properties((
        (margin, (v: 70px 0 -15px, v-sm-up: -25px 0 -30px)),
        (padding-bottom, (v: 110px, v-sm-up: 150px)),
    ));

    .milestone {
      position: relative;
      background-color: $white;
      z-index: 20;
      @include responsive-properties((
          (margin-bottom, (v-xs-down: $grid-gutter-width)),
          (padding-bottom, (v-xs-down: 150px)),
      ));

      &:nth-child(even) {

        .milestone-content {
          @include responsive-properties((
              (margin-left, (v-sm-up: 0)),
          ));
        }

        .milestone-thumbnail {
          @include responsive-properties((
              (right, (v-sm-up: 0)),
              (left, (v-sm-up: auto)),
          ));
        }
      }

      .milestone-content {
        padding: $spacer-v-lg $spacer-h-md;
        @include responsive-properties((
            (min-height, (v-sm-up: 300px)),
            (width, (v-sm-up: 50%)),
            (margin-left, (v-sm-up: 50%)),
        ));

        .milestone-title {
          margin-top: 0;
        }

        .milestone-text {

          p {

            &:last-of-type {
              margin: 0;
            }
          }
        }
      }

      .milestone-thumbnail {
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        @include responsive-properties((
            (height, (v: 200px, v-sm-up: auto)),
            (width, (v: 100%, v-sm-up: 50%)),
            (top, (v-sm-up: 0)),
            (bottom, (v-sm-up: 0)),
        ));
      }
    }

    .milestones-from,
    .milestones-to {
      width: 90px;
      height: 90px;
      line-height: 1;
      display: block;
      position: absolute;
      background: $brand-primary;
      border: 2px solid $brand-primary;
      z-index: 40;
      border-radius: 45px;

      .milestones-label {
        width: 90px;
        position: absolute;
        margin: 0;
        padding: 0;
        top: 50%;
        left: 50%;
        color: $white;
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;
        @include translateXY(-50%, -45%);
      }
    }

    .milestones-to {
      margin-left: -45px;
      top: -45px;
      left: 50%;
    }

    .milestones-from {
      margin-left: -45px;
      left: 50%;
      @include responsive-properties((
          (bottom, (v: $spacer-v-md, v-sm-up: $spacer-v-lg)),
      ));
    }

    &:after {
      content: "";
      width: 4px;
      margin-left: -2px;
      position: absolute;
      top: 40px;
      bottom: 60px;
      left: 50%;
      background-color: $brand-primary;
      z-index: 1;
      @include responsive-properties((
          (z-index, (v: 1, v-sm-up: 30)),
      ));
    }
  }
}
