// Gravity form
.gform_wrapper {
  @include clearfix;

  .gform_body {
    @include clearfix;

    .gform_fields {
      @include list-unstyled;
      margin: 0 ($input-gutter-width / 2 / -2);

      .gfield {
        padding-left: $input-gutter-width / 2 / 2;
        padding-right: $input-gutter-width / 2 / 2;

        .gfield_label {
          font-weight: $font-weight-base;
          margin-bottom: $spacer-v-xs / 2;

          .gfield_required {
            display: none;
          }
        }

        &.clearfix,
        &.gform_hidden,
        &.no-margin {
          margin-bottom: 0 !important;
        }

        select,
        textarea,
        input[type=text],
        input[type=number],
        input[type=tel],
        input[type=email],
        input[type=password],
        .chosen-single {
          line-height: $input-line-height;
          font-size: $input-font-size;
          appearance: none;
          -moz-appearance:none;
          -webkit-appearance: none;
        }

        textarea {
          resize: vertical;
          height: (($line-height-computed-small * 6) + ($padding-base-vertical * 2) + 2) ;
        }

        .ginput_container_select {
          position: relative;

          .chosen-single,
          select {
            padding-right: 45px;
            cursor: pointer;
          }

          &:after {
            font-family: 'icomoon';
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\e902";
            font-weight: bold;

            position: absolute;
            cursor: pointer;
            pointer-events: none;
            color: $brand-primary;
            font-size: $font-size-base;
            top: 50%;
            right: $padding-base-horizontal;
            margin-top: $font-size-base / -2;
          }
        }

        .chosen-container {
          min-width: 100%;
          max-width: 100%;

          .chosen-single {
            @extend .form-control;
            @include truncate-ellipsis;
            text-decoration: none;
          }

          .chosen-drop {
            position: relative;
            display: none;

            .chosen-search {
              display: none !important;
            }

            .chosen-results {
              background-clip: padding-box;
              position: absolute;
              top: 100%;
              list-style: none;
              left: 0;
              float: left;
              min-width: 100%;
              width: 100%;
              z-index: $zindex-dropdown;
              padding: 5px 0;
              margin: 2px 0 0;
              background-color: $dropdown-bg;
              border: 1px solid $dropdown-border;

              li {
                @include truncate-ellipsis;
                cursor: pointer;
                display: block;
                clear: both;
                padding: 4px 20px;
                color: $dropdown-link-color;

                &.highlighted {
                  text-decoration: none;
                  color: $dropdown-link-hover-color;
                  background-color: $dropdown-link-hover-bg;
                }

                &.result-selected {
                  text-decoration: none;
                  outline: none;
                  color: $dropdown-link-active-color;
                  background-color: $dropdown-link-active-bg;
                }
              }
            }
          }

          &.chosen-with-drop {
            .chosen-single {
              outline: none;
              border-color: $input-border-focus;
              @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba($input-border-focus, .6));
            }

            .chosen-drop {
              display: block;
            }
          }
        }

        select {
          line-height: 1;
        }

        select::-ms-expand {
          display: none;
        }

        &.has-placeholder {
          .ginput_container_select {
            .chosen-single,
            select {
              color: $input-color-placeholder;
            }
          }
        }

        .gfield_checkbox,
        .gfield_radio {
          @include list-unstyled;

          input[type=checkbox],
          input[type=radio] {
            display: none;

            &:checked + label {
              color: $brand-primary;

              &:before {
                background: $brand-primary;
                border-color: $brand-primary;
              }
            }
          }

          label {
            position: relative;
            cursor: pointer;
            padding-left: 22px;
            margin-bottom: $radio-checkbox-margin-bottom;
            color: $text-color;
            font-weight: $font-weight-base;
            font-size: $font-size-small;

            &:before {
              background: $input-bg;
              border: 1px solid $input-border;
              content: "";
              width: 15px;
              height: 15px;
              display: block;
              position: absolute;
              top: 3px;
              left: 0;
            }
          }

          li {
            &:last-of-type {
              label {
                margin-bottom: 0;
              }
            }
          }
        }

        .gfield_checkbox {
          label {
            &:before {
              border-radius: $border-radius-base;
            }
          }
        }

        .gfield_radio {
          label {
            &:before {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  .gform_footer {
    @include clearfix;

    .gform_ajax_spinner {
      display: none !important;
    }
  }

  .validation_message {
    color: $state-danger-text;
    margin-top: 2px;
    font-size: $font-size-small;
  }

  .validation_error {
    @extend .alert;
    @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
  }

  .gform_validation_container {
    display: none !important;
  }
}

.gforms_confirmation_message {
  @extend .alert;
  @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
  margin-bottom: 0;
}

// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}
