// Glyphicons font path
@font-face {font-family: "Avenir Heavy";
    src: url("../fonts/avenir-heavy-bold.eot"); /* IE9*/
    src: url("../fonts/avenir-heavy-bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/avenir-heavy-bold.woff2") format("woff2"), /* chrome、firefox */
    url("../fonts/avenir-heavy-bold.woff") format("woff"), /* chrome、firefox */
    url("../fonts/avenir-heavy-bold.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/avenir-heavy-bold.svg#Avenir Heavy") format("svg"); /* iOS 4.1- */
}

@font-face {font-family: "Avenir Roman";
    src: url("../fonts/avenir-roman-regular.eot"); /* IE9*/
    src: url("../fonts/avenir-roman-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/avenir-roman-regular.woff2") format("woff2"), /* chrome、firefox */
    url("../fonts/avenir-roman-regular.woff") format("woff"), /* chrome、firefox */
    url("../fonts/avenir-roman-regular.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/avenir-roman-regular.svg#Avenir Roman") format("svg"); /* iOS 4.1- */
  }


$icon-font-path:        "../fonts/";

$font-family-sans-serif: "Avenir Roman", sans-serif;
$font-family-base:        $font-family-sans-serif;

$text-color:              $gray-dark;

$link-hover-decoration:   none;

$font-size-base:          16px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~20px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~14px

$line-height-base:        1.5;
$line-height-computed:    floor(($font-size-base * $line-height-base));
$line-height-large:           1.3333333 !default;
$line-height-computed-large:  floor(($font-size-large * $line-height-large)) !default;
$line-height-small:           1.3333333 !default;
$line-height-computed-small:  floor(($font-size-small * $line-height-small)) !default;

$font-size-h1:            floor(($font-size-base * 4.4)); // ~70px
$font-size-h2:            floor(($font-size-base * 1.95)); // ~31px
$font-size-h3:            ceil(($font-size-base * 1.7)); // ~28px
$font-size-h4:            ceil(($font-size-base * 1.5)); // ~24px
$font-size-h5:            ceil(($font-size-base * 1.375)); // ~22px
$font-size-h6:            ceil(($font-size-base * 1.125)); // ~18px

$font-weight-thin:        100 !default;
$font-weight-light:       300 !default;
$font-weight-base:        400 !default;
$font-weight-medium:      500 !default;
$font-weight-semi-bold:   600 !default;
$font-weight-bold:        700 !default;
$font-weight-extra-bold:  800 !default;
$font-weight-ultra-bold:  900 !default;

$headings-font-family:    "Avenir Heavy", sans-serif;
$headings-font-weight:    $font-weight-base;
$headings-line-height:    1.1;
$headings-color:          $brand-primary;

$border-radius-base:      0;
$border-radius-small:     0;
$border-radius-large:     0;
