.post-type-archive-eds_distributor {

  .loc-title {
    text-transform: uppercase;
    font-size: $font-size-h4;
    font-weight: $font-weight-bold;
    margin: 0 0 $spacer-v-md;
  }

  .loc-placeholder {
    @include responsive-properties((
        (margin-bottom, (v-xs-down: $spacer-v)),
    ));
  }

  .loc-countries {

    .loc-country {

      a {
        font-weight: $font-weight-bold;
      }
    }
  }

  .back-to-countries {
    margin-top: $spacer-v-md;
  }

  .loc-distributors {

    .loc-distributor {
      @include responsive-properties((
          (margin-bottom, (v-xs-down: $spacer-v)),
      ));

      + .loc-distributor {
        margin-top: $spacer-v;
        padding-top: $spacer-v;
        border-top: 1px solid $gray-lighter;
      }

      p {
        margin: 0;

        a {
          color: $text-color;

          &:hover,
          &:active {
            color: darken($text-color, 15);
          }
        }

        span {
          color: $brand-primary;
          font-weight: $font-weight-bold;
        }
      }

      .loc-dist-title {
        font-size: $font-size-base;
        font-family: $font-family-base;
        font-weight: $font-weight-bold;
        line-height: $line-height-base;
        margin: 0;
      }

      .loc-dist-address {

        p {
          margin-bottom: $line-height-computed / 2;
        }
      }
    }
  }
}
