.slick-slider {

  .slick-slide {
    &:focus, a {
      outline: none;
    }
  }

  .slick-dots {
    text-align: center;
    margin: $spacer 0 0;
    @include list-unstyled;

    button {
      display: none;
    }

    li {
      margin: 0 2px -4px;
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      border: 1px solid $brand-primary;
      cursor: pointer;

      &.slick-active {
        background-color: $brand-primary;
        cursor: default;
      }
    }
  }

  &.dots-light {

    .slick-dots {
      text-align: left;

      li {
        border: 1px solid rgba($white, .15);

        &.slick-active {
          background-color: rgba($white, .15);
        }
      }
    }
  }
}
