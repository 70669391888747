.blog,
.tag,
.category {

  .type-post {

    .post-inner {
      display: block;
      padding: $spacer-v-md $spacer-h;
      color: $text-color;
      margin-bottom: $grid-gutter-width;
      @include clearfix;
      @include responsive-properties((
          (margin-left, (v-xs-down: $grid-gutter-width / -2)),
          (margin-right, (v-xs-down: $grid-gutter-width / -2)),
      ));

      .post-thumbnail {
        width: 140px;
        float: left;
        margin-right: $spacer-h-md;
      }

      .post-header {
        margin-bottom: $spacer-v;

        .post-title {
          margin: 0;
          font-size: $font-size-large;
        }

        .updated {
          color: $brand-primary;
          font-family: $headings-font-family;
          font-weight: $font-weight-bold;
        }
      }

      .post-summary {
        font-size: $font-size-base - 1;

        p {

          &:last-of-type {
            margin: 0;
          }
        }
      }

      &:hover {
        background-color: darken($white, 10);
      }
    }
  }
}

.single-post {

  .post-inner {
    @include clearfix;
    margin-bottom: $grid-gutter-width;

    .post-header {
      @include responsive-properties((
          (margin-bottom, (v: $spacer-v-md, v-sm: $spacer-v-lg)),
      ));

      .post-title {
        margin: 0;
        color: $gray;
        @include responsive-properties((
            (font-size, (v: $font-size-h4, v-sm-up: $font-size-h3)),
        ));
      }

      .updated {
        color: $brand-primary;
        font-family: $headings-font-family;
        font-weight: $font-weight-bold;
      }
    }

    .post-thumbnail {
      float: right;
      @include responsive-properties((
          (margin, (v: ($spacer-v-sm / -1) 0 $spacer-v $spacer-h, v-sm-up: ($spacer-v-sm / -1) 0 $spacer-v-md $spacer-h-md, v-md-up: ($spacer-v / -1) 0 $spacer-v-lg $spacer-h-lg)),
          (max-width, (v: 210px, v-sm-up: 240px, v-md-up: 270px, v-lg-up: 300px)),
      ));
    }
  }
}
