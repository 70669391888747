.dropdown-backdrop {
  position: static;
}

.dropdown {

  &.open {

    .dropdown-toggle {

      i {
        @include rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    border: 0;
    margin: 0;
    background-color: #00804a;
    @include box-shadow(none);
    @include responsive-properties((
        (float, (v-sm-down: none)),
        (position, (v-sm-down: static)),
        (text-align, (v-sm-down: center)),
        (padding, (v: 0 0 $spacer-v-sm, v-md-up: $spacer-v-sm 0 $spacer-v)),
    ));

    > li {

      > a {
        padding: $spacer-v-sm $spacer-h-md;
        @include responsive-properties((
            (white-space, (v-sm-down: normal)),
        ));
      }
    }
  }
}

