$header-bg:                 $white !default;

$header-top-height:         50px !default;

$sidenav-bg:                $brand-primary !default;
$sidenav-width:             320px !default;
$sidenav-inner-width:       260px !default;
$sidenav-toggle-bar-width:  35px !default;
$sidenav-toggle-bar-height: 4px !default;
$sidenav-toggle-bar-margin: 2px !default;
$sidenav-toggle-bar-bg:     $brand-primary !default;
$sidenav-toggle-height:     (($sidenav-toggle-bar-height + $sidenav-toggle-bar-margin) * 3) - $sidenav-toggle-bar-margin + ($spacer-v-md * 2);

// z-index

$header-sticky-bar-zindex:  1000 !default;
$header-brand-zindex:       1010 !default;
$sidenav-backdrop-zindex:   1019 !default;
$sidenav-zindex:            1020 !default;

