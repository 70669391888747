.bg-cover {
  @include bg-cover();
}

.bg-primary {
  @include bg-color($brand-primary);
}

.bg-white {
  @include bg-color($white);
}

.bg-black {
  @include bg-color($black);
}

.bg-dark-gray {
  @include bg-color($gray-dark);
}

.bg-light-gray {
  @include bg-color($gray-light);
}

.bg-gray-lighter {
  @include bg-color($gray-lighter);
}

.kill-br {
  @include kill-br;
}

.hidden-xxs {
  @include responsive-properties((
      (display, (v-xxs-down: none !important)),
  ));
}
