.entry-share {
  .entry-share-btns {
    @include clearfix;
    margin: 8px 0;
    float: right;
    @include responsive-properties((
        (float, (v-xxs-max: none)),
    ));

    li {
      display: inline-block;

      a {
        display: block;
        width: 34px;
        height: 34px;
        line-height: 34px;
        border-radius: 17px;
        border: 2px solid $gray;
        color: $gray;

        &:hover {
          color: darken($gray, 10);
          border-color: darken($gray, 10);
        }
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
