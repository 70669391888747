// Dropdowns

$dropdown-bg:                    $brand-success;

$dropdown-link-color:            $white;
$dropdown-link-hover-color:      $white;
$dropdown-link-hover-bg:         darken($brand-success, 10);

$dropdown-link-active-color:     $white;
$dropdown-link-active-bg:        darken($brand-success, 10);
