// Buttons

$btn-font-weight:                 $font-weight-semi-bold;
$btn-border-font-weight:          $font-weight-base !default;

$btn-font-size:                   $font-size-small !default;
$btn-font-size-lg:                $font-size-base !default;

$btn-line-height:                 $line-height-small !default;
$btn-line-height-lg:              $line-height-small !default;

$btn-border-border-radius:        1px !default;

// Button - Colours

$btn-default-color:               $white;
$btn-default-bg:                  $brand-primary;
$btn-default-border:              $btn-default-bg;

$btn-primary-color:               $white;
$btn-primary-bg:                  $brand-primary;
$btn-primary-border:              $btn-primary-bg;

$btn-success-color:               $white;
$btn-success-bg:                  $brand-success;
$btn-success-border:              $btn-success-bg;

$btn-info-color:                  $gray-dark;
$btn-info-bg:                     $brand-info;
$btn-info-border:                 $btn-info-bg;

$btn-warning-color:               $brand-success;
$btn-warning-bg:                  $brand-warning;
$btn-warning-border:              $btn-warning-bg;

$btn-danger-color:                $white;
$btn-danger-bg:                   $brand-danger;
$btn-danger-border:               $btn-danger-bg;

$btn-white-color:                 $brand-primary;
$btn-white-bg:                    $white;
$btn-white-border:                $btn-white-bg;

$btn-dark-color:                  $white;
$btn-dark-bg:                     $gray-dark;
$btn-dark-border:                $gray-dark;


// Border only Buttons - Colours

$btn-b-default-color:         $white;
$btn-b-default-h-color:       $white;
$btn-b-default-bg:            rgba($white, .2);
$btn-b-default-border:        $white;

$btn-b-primary-color:         $brand-primary;
$btn-b-primary-h-color:       $brand-primary;
$btn-b-primary-bg:            rgba($brand-primary, .2);
$btn-b-primary-border:        $brand-primary;

$btn-b-success-color:         $brand-success;
$btn-b-success-h-color:       $brand-success;
$btn-b-success-bg:            rgba($brand-success, .2);
$btn-b-success-border:        $brand-success;

$btn-b-info-color:            $gray-dark;
$btn-b-info-h-color:          $gray-dark;
$btn-b-info-bg:               $brand-info;
$btn-b-info-border:           $brand-info;

$btn-b-warning-color:         $brand-success;
$btn-b-warning-h-color:       $brand-success;
$btn-b-warning-bg:            $brand-warning;
$btn-b-warning-border:        $btn-warning-bg;

$btn-b-danger-color:          $brand-danger;
$btn-b-danger-h-color:        $brand-danger;
$btn-b-danger-bg:             rgba($brand-danger, .2);
$btn-b-danger-border:         $brand-danger;
