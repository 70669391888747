.modal {
  text-align: center;

  &:before {
    content: " ";
    @include responsive-properties((
        (display, (v-sm-up: inline-block)),
        (vertical-align, (v-sm-up: middle)),
        (height, (v-sm-up: 100%)),
    ));
  }

  &.team {

    .modal-dialog {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      max-width: $modal-md;
      margin: $spacer-v-md $spacer-h;

      .modal-content {

        .modal-header {
          @include clearfix;
          display: table;
          table-layout: fixed;
          height: 100%;
          width: 100%;
          border: 0;

          .photo-container,
          .title-container {
            width: 50%;
            display: table-cell;
            height: 100%;
            vertical-align: middle;
          }

          .member-photo {
            padding-top: 100%;
          }

          .member-job,
          .member-name {
            font-family: $font-family-base;
            font-size: $font-size-h6;
          }

          .member-name {
            color: $brand-primary;
            margin-bottom: $spacer-xs / 2;
            font-weight: $font-weight-bold;
          }

          .member-job {
            color: $text-color;
          }

          .close {
            text-shadow: none;
            color: $brand-primary;
            opacity: 1;
            font-size: $font-size-h4;
            margin: $spacer-v-xs $spacer-h-xs 0 0;
            outline: none;

            @media (min-width: 550px) {
              margin: 0 $spacer-h-hd / -1 0 0;
              font-size: $font-size-h3;
            }

            &:hover,
            &:focus {
              color: darken($brand-primary, 10);
            }
          }
        }

        .modal-body {
          font-size: $font-size-base - 1;
          @include responsive-properties((
              (padding, (v-xxs-down: $spacer-md)),
          ));
        }
      }
    }
  }
}

.modal-backdrop.in {
  opacity: 1;
}
