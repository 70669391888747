.page-footer {

  .pf-item {
    @include responsive-properties((
        (padding, (v: 0 0 $spacer-v, v-md-up: $spacer-v-md 0)),
    ));

    &:first-of-type {
      .sidebar-hidden-xs & {
        @include responsive-properties((
            (padding-top, (v-xs-down: $spacer-v-lg)),
        ));
      }
    }

    .pf-title { 
      color: $text-color;
      margin: 0 0 $spacer-lg;
      @include responsive-properties((
          (margin, (v: 0 0 $spacer-md)),
      ));
    }
  }
}
