.pc-item.l-product-selector {

  .selector-table {

    table {
      width: 100%;
      font-size: $font-size-small;
      text-align: center;
      min-width: 480px;

      thead {

        tr {

          td {
            width: 25%;
            height: 100px;

            &.sensor {
              width: 10%;
              background-color: #e0ebe7;
              height: 110px;
              white-space: nowrap;
              vertical-align: bottom;

              span {
                display: block;
                @include rotate(-90deg);
                font-size: $font-size-small;
                padding: 0;
                width: 20px;
                margin: 0 0 $spacer-v-sm ($spacer-h-xs / 2);
                font-weight: $font-weight-bold;
              }
            }
          }

          th {
            background-color: $gray-dark;
            color: $white;
            text-align: center;
            padding: $spacer-v-sm $spacer-h-md;

            &.sensors {
              background-color: $brand-primary;
            }
          }
        }
      }

      tbody {

        tr {

          td {
            width: 10%;
            padding: ($spacer-v-xs / 2) $spacer-h-xs;
            text-align: center;
            background-color: #e0ebe7;

            &.text {
              background-color: $white;
              width: 25%;
            }

            span {
              display: inline-block;
              width: 10px;
              height: 10px;

              &.pa {
                background-color: $brand-primary;
              }

              &.da {
                background-color: #0099CC;
              }

              &.dp {
                background-color: #990000;
              }
            }
          }

          &.empty {

            td {
              padding: ($spacer-v / 2) 0;
              background-color: transparent;
            }
          }

          &.gray {

            td {
              background-color: $gray-lighter;
            }
          }
        }
      }
    }
  }
}
