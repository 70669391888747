.posts-navigation {
  @include responsive-properties((
      (padding-top, (v-sm-up: $grid-gutter-width / 2)),
  ));
}

.numeric-products-navigation {
  @include responsive-properties((
      (padding-top, (v: $spacer-v-md, v-sm-up: $spacer-v-lg)),
  ));

  .page-numbers {
    margin: 0;
    display: inline-block;
    text-align: center;
    font-size: $font-size-small;
    background-color: darken($gray-light, 3);
    color: darken($gray, 10);
    width: 50px;
    height: 50px;
    line-height: 50px;

    &.current,
    &:hover,
    &:focus {
      background-color: $white;
    }

    &.dots {
      width: auto;
      color: darken($gray, 10);
      background-color: transparent !important;
    }
  }
}
