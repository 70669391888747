.pc-item.l-content {

  .pc-c-header {
    margin-bottom: $spacer-v-md;

    .pc-c-title {
      margin: 0;
      @include responsive-properties((
          (font-size, (v-xs-down: $font-size-h3)),
      ));
    }

    .pc-c-lead {
      margin: $spacer-v-sm 0 0;
      color: $gray;
      line-height: $line-height-small;
      @include responsive-properties((
          (font-size, (v: $font-size-h6, v-sm-up: $font-size-large)),
      ));
    }
  }
}
