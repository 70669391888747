@mixin translateY($value) {
  -webkit-transform: translateY($value);
  -ms-transform: translateY($value);
  transform: translateY($value);
}

@mixin translateX($value) {
  -webkit-transform: translateX($value);
  -ms-transform: translateX($value);
  transform: translateX($value);
}

@mixin translateXY($valueX, $valueY) {
  -webkit-transform: translateX($valueX) translateY($valueY);
  -ms-transform: translateX($valueX) translateY($valueY);
  transform: translateX($valueX) translateY($valueY);
}

@mixin bg-cover() {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@mixin bg-color($color) {
  background-color: $color;
}

@mixin truncate-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin break-word() {
  word-wrap: break-word;
  white-space: normal;
}

@mixin kill-br() {
  br {
    display: none;
  }
}

@mixin kill-gmap() {
  .gm-style-cc,
  .gmnoprint,
  .gmnoprint div {
    display:none !important;
  }
}
