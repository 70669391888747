.content-info {
  font-size: $font-size-base - 1;

  .sign-up {
    background-color: $brand-primary;
    padding: $spacer-v-lg 0;
    text-align: center;

    .sign-up-inner {
      margin: ($spacer-v-sm / -1) ($spacer-h-md / -1);

      h3 {
        margin: $spacer-v-sm 0;
        color: $white; 
        font-weight: $font-weight-bold;
        display: inline-block;
        vertical-align: middle;
        padding: 0 $spacer-h-md;
        @include responsive-properties((
            (font-size, (v: $font-size-h4, v-lg-up: $font-size-h3)),
        ));
      }

      .btn {
        display: inline-block;
        vertical-align: middle;
        margin: $spacer-v-sm $spacer-h-md;
      }
    }
  }

  .top {
    @include responsive-properties((
        (padding, (v: $spacer-v-lg 0 $spacer-v-md, v-sm-up: $spacer-v-hd 0 $spacer-v-lg)),
    ));

    .footer-widgets {

      .fw-title {
        font-weight: $font-weight-bold; 
        margin: 0 0 $spacer-v-md;
      }
    }
  }

  .bottom {
    border-top: 1px solid $white;
    @include responsive-properties((
        (padding, (v: $spacer-v, v-sm-up: $spacer-v-md)),
    ));

    p {
      margin: 0;
      font-size: $font-size-small;
      @include responsive-properties((
          (text-align, (v-xs-down: center)),
      ));
    }

    a {
      color: $text-color;

      &:hover,
      &:active {
        color: darken($text-color, 15);
      }
    }
  }
}
