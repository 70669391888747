.content-inner {
  @extend .bg-white;
  @include responsive-properties((
      (margin, (v-xs-down: 0 ($grid-gutter-width / -2))),
      (padding, (v: $spacer-v-lg ($grid-gutter-width / 2), v-sm-up: $spacer-v-lg $spacer-h-md, v-md-up: $spacer-hd $spacer-lg)),
  ));
}

.pc-item {

  + .pc-item {
    @include responsive-properties((
        (margin-top, (v: $spacer-v-lg, v-md: $spacer-v-lg + $spacer-v-xs)),
    ));
  } 
}

