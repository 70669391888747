.footer-widget.find-our-way {

  .fow-title {
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;
    margin: 0;
  }

  .fow-nav {

    .nav {
      @include list-unstyled;

      li {

        a  {
          background: transparent;
          color: $text-color;
          padding: 0;

          &:hover,
          &:active {
            color: darken($text-color, 15);
          }
        }
      }
    }
  }
}
