body {
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  &.side-nav-active {
    overflow-y: hidden;
  }
}

.wrap {
  @include responsive-properties((
      (padding, (v: 0 0 $grid-gutter-width, v-sm-up: $grid-gutter-width 0 $spacer-v-hd, v-md-up: $grid-gutter-width 0 ($spacer-v-hd + $spacer-v-md))),
  ));

  .content {
    @include responsive-properties((
        (margin-bottom, (v-sm-up: $grid-gutter-width)),
    ));
  }
}
