@mixin responsive-properties($properties) {
  $actual-media-queries: 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0;

  @each $property, $attribute in $properties {

    @if (map-has-key($attribute, v) and (index($actual-media-queries, v) == null)) {
      $actual-media-queries: replace-nth($actual-media-queries, 1, v);
    }

    @if (map-has-key($attribute, v-xxs-down) and (index($actual-media-queries, v-xxs-down) == null)) {
      $actual-media-queries: replace-nth($actual-media-queries, 2, v-xxs-down);
    }

    @if (map-has-key($attribute, v-xs-down) and (index($actual-media-queries, v-xs-down) == null)) {
      $actual-media-queries: replace-nth($actual-media-queries, 3, v-xs-down);
    }

    @if (map-has-key($attribute, v-xs-up) and (index($actual-media-queries, v-xs-up) == null)) {
      $actual-media-queries: replace-nth($actual-media-queries, 4, v-xs-up);
    }

    @if (map-has-key($attribute, v-xs) and (index($actual-media-queries, v-xs) == null)) {
      $actual-media-queries: replace-nth($actual-media-queries, 5, v-xs);
    }

    @if (map-has-key($attribute, v-sm-down) and (index($actual-media-queries, v-sm-down) == null)) {
      $actual-media-queries: replace-nth($actual-media-queries, 6, v-sm-down);
    }

    @if (map-has-key($attribute, v-sm-up) and (index($actual-media-queries, v-sm-up) == null)) {
      $actual-media-queries: replace-nth($actual-media-queries, 7, v-sm-up);
    }

    @if (map-has-key($attribute, v-sm) and (index($actual-media-queries, v-sm) == null)) {
      $actual-media-queries: replace-nth($actual-media-queries, 8, v-sm);
    }

    @if (map-has-key($attribute, v-md-down) and (index($actual-media-queries, v-md-down) == null)) {
      $actual-media-queries: replace-nth($actual-media-queries, 9, v-md-down);
    }

    @if (map-has-key($attribute, v-md-up) and (index($actual-media-queries, v-md-up) == null)) {
      $actual-media-queries: replace-nth($actual-media-queries, 10, v-md-up);
    }

    @if (map-has-key($attribute, v-md) and (index($actual-media-queries, v-md) == null)) {
      $actual-media-queries: replace-nth($actual-media-queries, 11, v-md);
    }

    @if (map-has-key($attribute, v-lg-down) and (index($actual-media-queries, v-lg-down) == null)) {
      $actual-media-queries: replace-nth($actual-media-queries, 12, v-lg-down);
    }

    @if (map-has-key($attribute, v-lg-up) and (index($actual-media-queries, v-lg-up) == null)) {
      $actual-media-queries: replace-nth($actual-media-queries, 13, v-lg-up);
    }
  }

  @each $media-query in $actual-media-queries {

    @if ($media-query == v) {
      @each $property, $attribute in $properties {
        #{$property}: map-get($attribute, v);
      }
    }

    @if ($media-query == v-xxs-down) {
      @media (max-width: ($screen-xs-min - 1)) {
        @each $property, $attribute in $properties {
          #{$property}: map-get($attribute, v-xxs-down);
        }
      }
    }

    @if ($media-query == v-xs-down) {
      @media (max-width: $screen-xs-max) {
        @each $property, $attribute in $properties {
          #{$property}: map-get($attribute, v-xs-down);
        }
      }
    }

    @if ($media-query == v-xs-up) {
      @media (min-width: $screen-xs-min) {
        @each $property, $attribute in $properties {
          #{$property}: map-get($attribute, v-xs-up);
        }
      }
    }

    @if ($media-query == v-xs) {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        @each $property, $attribute in $properties {
          #{$property}: map-get($attribute, v-xs);
        }
      }
    }

    @if ($media-query == v-sm-down) {
      @media (max-width: $screen-sm-max) {
        @each $property, $attribute in $properties {
          #{$property}: map-get($attribute, v-sm-down);
        }
      }
    }

    @if ($media-query == v-sm-up) {
      @media (min-width: $screen-sm-min) {
        @each $property, $attribute in $properties {
          #{$property}: map-get($attribute, v-sm-up);
        }
      }
    }

    @if ($media-query == v-sm) {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        @each $property, $attribute in $properties {
          #{$property}: map-get($attribute, v-sm);
        }
      }
    }

    @if ($media-query == v-md-down) {
      @media (max-width: $screen-md-max) {
        @each $property, $attribute in $properties {
          #{$property}: map-get($attribute, v-md-down);
        }
      }
    }

    @if ($media-query == v-md-up) {
      @media (min-width: $screen-md-min) {
        @each $property, $attribute in $properties {
          #{$property}: map-get($attribute, v-md-up);
        }
      }
    }

    @if ($media-query == v-md) {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        @each $property, $attribute in $properties {
          #{$property}: map-get($attribute, v-md);
        }
      }
    }

    @if ($media-query == v-lg-down) {
      @media (max-width: $screen-md-max) {
        @each $property, $attribute in $properties {
          #{$property}: map-get($attribute, v-lg-down);
        }
      }
    }

    @if ($media-query == v-lg-up) {
      @media (min-width: $screen-lg-min) {
        @each $property, $attribute in $properties {
          #{$property}: map-get($attribute, v-lg-up);
        }
      }
    }
  }
}

